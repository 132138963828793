import React from "react"
import  right_arrow_blue  from "../../images/right_arrow_blue.svg";
import  left_arrow_blue  from "../../images/left_arrow_blue.svg";
import  ETNOW  from "../../images/ETNOW.png";
import  Outlook_Money  from "../../images/Outlook_Money.png";
import SoftwareSuggest from '../../images/SS_ReviewBadge.png';
import styles from "./index.module.css";
import Slider from "react-slick";
import Pagination from "react-js-pagination";
import ReviewItem from "../../shared/reviewItem";
import ReviewModal from "../../shared/reviewModal";
import { shuffleArray } from "../../common/gFunctions";

class Review extends React.Component {

    constructor(props){
        super(props);
        const {totalCount} = this.props.data.allWordpressWpReview;
        const {index, perPage} = this.props;

        const {reviewsContent} = this.props;

        const topreviews = shuffleArray(reviewsContent.topreviews);

        this.state = {
            currentSlide: 1,
            modal: false,
            reviewContent: "",
            defaultLength : 410,
            remainingCount: totalCount - (index * perPage),
            length: totalCount,
            topreviews
        }

        this.listTopRef = React.createRef() 
    }
    next = () => {
        this.slider.slickNext();
    }
    previous = () => {
        this.slider.slickPrev();
    }
    
    toggleModal=() =>{
        this.setState(prevState => ({
          modal: !prevState.modal
        }));
    }
  
    componentDidMount() {
        window.addEventListener("resize", this.updateCharacterLength);
        this.updateCharacterLength();

        if (this.props.location && this.props.location.state && this.props.location.state.scroll) {
            this.scrollTolistTopRef();
        }
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateCharacterLength);
    }

    updateCharacterLength = () => {
        if( window.innerWidth > 1200){
            this.setState({defaultLength: 410});
        }
        if( window.innerWidth < 1200){
            this.setState({defaultLength: 310});
        }
        if( window.innerWidth < 992 && window.innerWidth > 400){
            this.setState({defaultLength: 200});
        }
        if(window.innerWidth <= 400 && window.innerWidth > 322)
        {
            this.setState({defaultLength: 250});
        }
        if(window.innerWidth <= 322)
        {
            this.setState({defaultLength: 180});
        }
    }

    scrollTolistTopRef = () => {
        setTimeout(()=>{
          window.scrollTo({ behavior: 'smooth', top: this.listTopRef.current.offsetTop-150 });
    
        },5);
    }
    
    readMoreCon = (review,context) => {    
        this.setState({ 
            reviewContent: review,
            modal: true,
        });
    }

    render(){
        const {index, numPages, perPage, totalCount} = this.props;
        var reviewList = [];
        const reviews = this.props.data.allWordpressWpReview.edges;
        if (reviews) {
            for ( var i = 0; i < reviews.length; i++) {
                reviewList.push(
                    <div className={["col-md-6"].join(" ")} key={i}>
                        <ReviewItem review={reviews[i].node} readMore={this.readMoreCon}/>
                    </div>
                )
            }
        }
        return(
            <>
                <div className={styles.review_main_section}>
                    <div className={[styles.mpr_title_section, "d-flex"].join(" ")}>
                        <div className={["container", "align-self-center"].join(" ")}>
                            <div className={["row", "justify-content-md-center"].join(" ")}>
                                <div className={[styles.mpr_main_title ,'col-lg-12','text-center'].join(' ')}>
                                    <h2 className="mb-2">{`Read over ${Math.floor(totalCount/100)*100} reviews from happy MProfit customers`}</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={[styles.review_slider_section,"top_reviews_slider"].join(" ")}>
                        <div className="container">
                            <Slider beforeChange = {
                                    (nextSlide, currentSlide) => {
                                       this.setState({ currentSlide: currentSlide  + 1 })
                                    }
                                    
                                }
                                swipeToSlide={true}
                                // autoplay={true}
                                speed={1000}
                                autoplaySpeed={10000}
                                arrows={false}
                                infinite={true}
                                pauseOnHover={false}
                                ref={c => (this.slider = c)}>
                                    {
                                        this.state.topreviews.map((review,index) => (
                                            <div key={index}>
                                                <div className={["row", "justify-content-md-center"].join(" ")}>
                                                    <div className="col-md-10">
                                                        <div className={[styles.slider_item,'pb-lg-3', 'text-center d-flex flex-column justify-content-between'].join(' ')}>
                                                            <h3 className={["mb-5"].join(" ")}>{review.text}</h3>
                                                            <div className={styles.review_name}>{review.name}</div>
                                                            {/* <div className={styles.designation}>Co-founder at SoftwareSuggest.com</div> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    }
                            </Slider>
                            <div className={[styles.bottom_arrows,'col-md-12', 'text-center', 'd-block'].join(' ')}>
                                <img src={left_arrow_blue} onClick={this.previous} alt="Previous Review"/>
                                    <span className={["ml-3", "mr-3"].join(" ")}></span>
                                <img src={right_arrow_blue}onClick={this.next} alt="Next Review"/>
                            </div>
                        </div>
                    </div>
                    <div className={styles.review_box_main_section}>
                        <div className={styles.mpr_review_title_section}>
                            <div className="container mb-4">
                                <div className="row">
                                    <div className={["col-12", "text-center", "pt-0", "pb-4"].join(" ")}>
                                        <h2 className={styles.featuredInText}>MProfit has been featured in</h2>
                                    </div>
                                </div>
                                <div className={["row", "justify-content-center","align-items-center"].join(" ")}>
                                    <div className={['col-lg-2 col-md-3 col-4','text-center'].join(' ')}>
                                        <a href="https://www.softwaresuggest.com/mprofit#user-reviews" target="_blank" rel="noopener noreferrer"><img src={SoftwareSuggest} alt="Software Suggest 5/5"/></a>
                                    </div>
                                    <div className="col-lg-1 d-none d-lg-block"></div>
                                    <div className={['col-lg-2 col-md-3 col-3','text-center'].join(' ')}>
                                        <img src={ETNOW} alt="ET Now Logo"/>
                                    </div>
                                    <div className="col-lg-1 d-none d-lg-block"></div>
                                    <div className={['col-lg-2 col-md-3 col-4','text-center'].join(' ')}>
                                        <img src={Outlook_Money} alt="Outlook Money Logo"/>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                        <div className={styles.review_box_section} ref={this.listTopRef}>
                            <div className={["container",styles.reviewListSection].join(" ")}>
                                <div className={["row", "m-0"].join(" ")}>
                                {  reviewList }                   
                                </div>
                                <div className="container ">
                                    <div className={["col-md-12", "text-center"].join(" ")}>
                                        <nav aria-label="Page navigation" className={["col", "mt-md-5", "mt-2", "mb-2", "mb-md-5", "d-md-block", "d-none"].join(" ")}>
                                            <Pagination
                                                hideDisabled
                                                activePage={index}
                                                itemsCountPerPage={perPage}
                                                totalItemsCount={ perPage * numPages}
                                                pageRangeDisplayed={10}
                                                itemClass="page-item"
                                                linkClass="page-link"
                                                innerClass="justify-content-center pagination"
                                                // hideFirstLastPages={true}
                                                onChange={this.props.handlePageChange}
                                            />
                                        </nav>
                                        <nav aria-label="Page navigation" className={["col", "mt-md-5", "mt-2", "mb-2", "mb-md-5", "d-md-none", "d-block"].join(" ")}>
                                            <Pagination
                                                hideDisabled
                                                activePage={index}
                                                itemsCountPerPage={perPage}
                                                totalItemsCount={ perPage * numPages}
                                                pageRangeDisplayed={5}
                                                itemClass="page-item"
                                                linkClass="page-link"
                                                innerClass="justify-content-center pagination"
                                                // hideFirstLastPages={true}
                                                onChange={this.props.handlePageChange}
                                            />
                                        </nav>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {this.state.modal && this.state.reviewContent && 
                        <ReviewModal isOpen={this.state.modal} toggleModal={this.toggleModal} review={this.state.reviewContent}/>
                    }
                </div>
            </>
        )
    }
}



export default Review
