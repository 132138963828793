import React from "react"
import { graphql, navigate } from "gatsby"
import Layout from "../components/flayout"
import Review from "../components/review";

export default class ReviewList extends React.Component {

  handlePageChange = (pageNumber) => {
    navigate('/reviews/'+(pageNumber===1 ? "" : pageNumber), {
      state: { scroll: true },
    });
  }

  render() {
    const index = this.props.pageContext.index;
    const numPages = this.props.pageContext.numPages;
    const postsPerPage = 20;
    return (
        <Layout darkHeader={false} seoFields={seoFields}>
      	    <Review data={this.props.data} totalCount={this.props.data.allWordpressWpReview.totalCount} index={index} numPages={numPages} perPage={postsPerPage} handlePageChange={this.handlePageChange} location={this.props.location} reviewsContent={this.props.data.allContentJson.edges[0].node.reviewsContent}/>
        </Layout>
    )
  }
}

const seoFields = {
  description: "Read over 500 reviews from happy MProfit customers and learn how MProfit has been valuable to them in seamlessly managing investment portfolios and making better investment decisions.",
  // lang,
  // meta,
  // keywords,
  title: "Reviews" 
}


export const reviewListQuery = graphql`
  query reviewListQuery($skip: Int!, $limit: Int!) {
    allWordpressWpReview(
        sort: { fields: [date], order: DESC }
        limit: $limit
        skip: $skip
    ) {
        edges {
            node {
                title
                content
                date
                excerpt
            }
        }
        totalCount
    }
    allContentJson {
      edges {
        node {
          reviewsContent {
            banner {
              headline
            }
            topreviews {
              name
              text
            }
          }
        }
      }
    }
  }
`